.content-header {
    margin: 10px 0;
    padding-bottom: 5px;
    border-bottom: 1.7px solid $secondary;
    & h1 {
        letter-spacing: 2px;
    }
    & h2 {
        letter-spacing: 2px;
        font-weight: 400;
    }
}

.content-header-sub {
    margin: 10px 0;
    border-bottom: 1px solid $secondary-dark;
    & h1 {
        letter-spacing: 2px;
    }
    & h2 {
        letter-spacing: 2px;
        font-weight: 400;
        font-size: 1.2rem;
    }
}

.content-container {
    margin: 30px 100px;
}

.content-list-row {
    border-bottom: 1px solid $secondary-dark;
    margin: 20px;
    font-size: 1.1em;
}

.content-list-row-rules {
    border-bottom: 1px solid $secondary-dark;
    padding-bottom: 10px;
    margin: 20px;
    font-size: 1.1em;
}

.content-list-row-user-list {
    border-bottom: 1px solid $secondary-dark;
    margin: 30px 100px;
    font-size: 1.1em;
}

.content-list-row p{
    width: 200px;
}

.content-form-group {
    display: flex;
    flex-direction: row;
    margin: 10px;
    & input, label, select {
        font-size: 1rem;
        font-weight: 400;
        margin: 2px;
    }
    & label {
        font-size: 1.1rem;
        padding-top: 2px;
        padding-right: 10px;
        min-width: 150px;
    }
    & input {
        background-color: $gray-light;
        min-width: 250px;
        padding: 5px;
        border-radius: 5px;
        height: 40px;
        box-shadow: 2px 2px 5px 0px $gray;
        border-width: 0px;
    }
    & select {
        background-color: $gray-light;
        min-width: 250px;
        padding: 5px;
        border-radius: 5px;
        height: 40px;
        box-shadow: 2px 2px 5px 0px $gray;
        border-width: 0px;
    }
    @media screen and (max-width: $mediumScreen) {
        flex-direction: column;
      }
}

.content-button-container {
    float: right;
  }

.invalid-input {
    margin-top: .25rem;
    font-size: 80%;
    padding: 7px;
    color: #dc3545;
}

.is-invalid {
    border-color: #dc3545!important;
    border-width: 3px !important
}

.checkbox-container {
    font-size: 1rem;
  }

.checkbox-container input{
    border: grey solid 1px;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: white;
}

.content-search{
    background-color: rgb(245, 245, 245);
    color: $dark;
    border: none;
    background-image: url('/assets/images/search.svg');
    background-repeat: no-repeat;
    background-position: right;
    background-position-y: 6px;
    outline: none;
    height: 35px;
    margin-left: auto;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px;
    width: 80%
}


.content-search-sm{
    background-size: 18px;
    height: 20px;
    width: 100px;
  }

@media screen and (max-width: $largeScreen) {
    .content-container {
        margin: 0;
    }
    .content-search{
        // width: 100px;
      }

    .content-list-row p{
        width: 130px;
    }
  }

  .user-dropdown {
    position: absolute;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 0px;
    width: auto;
    background-color: whitesmoke;
    z-index: 900;
    & p {
        font-size: 17px;
    }
    & p:hover {
        background-color: $ocean-blue;
        cursor: pointer;
    }
  }

  .user-dropdown-wrapper {
      position: relative;
  }


  .value-input {
    min-width: 180px;
    background-color: $gray-light;
    padding: 5px;
    border-radius: 5px;
    height: 40px;
    box-shadow: 2px 2px 5px 0px $gray;
    border-width: 0px;
  }

  .value-input180 {
    min-width: 180px;
    background-color: $gray-light;
    padding: 5px;
    border-radius: 5px;
    height: 40px;
    box-shadow: 2px 2px 5px 0px $gray;
    border-width: 0px;
  }

.object-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25vw;
    height: 25vw;
    text-align: center;
    color: white;
    background-color: $secondary;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

