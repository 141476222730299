$dark: black;
$light: white;
$primary: #03143a;
$primary-light: lighten($primary, 20%);
$secondary: #190D34;
$secondary-light: lighten($secondary, 35%);
$secondary-dark: darken($secondary, 20%);
$invalid: #dc3545;
$disabled-button: #687289;
$modal-background: rgba(0,0,0,0.5);


$footerHeight: 55px;
$headerHeight: 44px;
$sidebarWidth: 270px;

//Screen sizes
// xs 0-600
// s  600-960
// m  960-1280
// l  1280-1920
// xl 1920+
$smallScreen: 600px;
$mediumScreen: 960px;
$largeScreen: 1300px;


$ocean-blue: #0462F8;
$ocean-blue-light: lighten($ocean-blue, 20%);
$gray: #adadad;
$gray-light: #F6F4F4;
$gray-dark: #636264;
$orange: #ED634A;
$orange-light: lighten($orange, 20%);
$orange-dark: darken($orange, 20%);
