.i-equal {
    background: transparent url('/assets/images/equal.svg') no-repeat center center;
    padding: 7px;
    background-size: 12px;
}

.i-less {
    background: transparent url('/assets/images/less.svg') no-repeat center center;
    padding: 7px;
    background-size: 12px;
}

.i-greater {
    background: transparent url('/assets/images/greater.svg') no-repeat center center;
    padding: 7px;
    background-size: 12px;
}

.i-less-or-equal {
    background: transparent url('/assets/images/less-or-equal.svg') no-repeat center center;
    padding: 7px;
    background-size: 12px;
}

.i-greater-or-equal {
    background: transparent url('/assets/images/greater-or-equal.svg') no-repeat center center;
    padding: 7px;
    background-size: 12px;
}