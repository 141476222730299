.action-card {
  display: flex;
  flex-direction: column;
  background-color: $gray-light;
  margin-bottom:5px;
  margin-top:5px;
  cursor:pointer;
  border-radius: 5px;
}

.action-card-first-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px
}

.action-card-content {
  color: black;
  padding-left: 50px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: $gray;
  border-radius: 0px 0px 5px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.action-card-icon {
  margin:5px;
  border-radius: 50px 50px 50px 50px;
  // padding: 22px !important;
  // background-size: 34px !important;

}

.action-card-header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.action-card-subheader {
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: smaller;
  width:100%
}
